<template>
  <div>
    <div class="instructions-back-btn">
      <b-button
        class="hubu-btn"
        size="lg"
        variant="dark"
        @click="goHome()"
      >
        {{ $t("home") }}
      </b-button>
    </div>
    <p class="m-3 text-center">
      {{ appText('instructions_clickable') }}
    </p>
    <b-col
      xl="3"
      lg="4"
      sm="12"
      offset-sm="0"
      offset-lg="3"
      offset-xl="4"
    >
      <InstructionsCardsDeck
        :cards="deck"
        palette="black"
        @cardAccepted="handleCardAccepted"
        @cardRejected="handleCardRejected"
        @cardSkipped="handleCardSkipped"
        @goUp="handleGoUp"
        @hideCard="removeCardFromDeck"
      />
    </b-col>
  </div>
</template>

<script>
import InstructionsCardsDeck from "./InstructionsCardsDeck";

export default {
  name: "Instructions",
  components: {
    InstructionsCardsDeck,
  },

  data () {
    return {};
  },

  computed: {
    gameType () {
      return this.$store.getters.getGameType;
    },
    deck () {
      return this.$store.getters.getInstr;
    },
    stackeddeck () {
      return this.$store.getters.getStackedDeck;
    },
  },

  beforeMount () {
    this.$store.dispatch("getDecks");
  },

  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    goHome () {
      this.$router.push({ name: "Home" });
    },
    handleCardAccepted () {
      // console.log("handleCardAccepted");
    },
    handleCardRejected () {
      // console.log("handleCardRejected");
    },
    handleCardSkipped () {
      // console.log("handleCardSkipped");
    },
    handleGoUp () {
      // console.log("handleGoUp");
    },
    removeCardFromDeck () {
      //this.visibleCards.shift();
    },
  },
};
</script>

<style lang="scss">
.instructions-back-btn {
  position: absolute;
  top: 40%;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
}
</style>