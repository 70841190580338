<template>
  <div
    class="cards"
    :class="{
      'cardsMobile': isMobile
    }"
  >
    <InstructionsCard
      v-for="(card, index) in cards"
      :key="index"
      :card="card"
      :is-current="true"
      @cardAccepted="$emit('cardAccepted');"
      @cardRejected="$emit('cardRejected');"
      @cardSkipped="$emit('cardSkipped');"
      @goUp="$emit('goUp');"
      @hideCard="$emit('hideCard');"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import InstructionsCard from "./InstructionsCard";

export default {
  components: {
    InstructionsCard,
  },

  props: {
    cards: {
      type: Array,
      required: true,
    },
  },

  computed: {
    isMobile () {
      return (Cookies.get("mobile") === "false") ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>

.cards {
  position: relative;
  display: flex;
  margin: 50px;
  width: 100%;
}

.cardsMobile{
  width: 75%;
  margin: 15% !important;
}
</style>